import { useStore } from "@nanostores/react";
import { $currentTrack } from "@src/state/music";
import type { FC } from "react";

export const PlayerFooter: FC = () => {
  const playerVisible = !!useStore($currentTrack);

  const className = {
    default: "hidden",
    visible: "h-16 flex lg:h-24",
  };

  return (
    <div className={playerVisible ? className.visible : className.default} />
  );
};
